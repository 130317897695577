import React from "react";

const MovieSkelton = () => {
  const containerStyles = {
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
  };
  return (
    <div className=" flex-col flex lg:px-20 px-5" style={containerStyles}>
      <div class="flex justify-center items-center relative w-full animate-pulse">
        <div class="flex flex-col bg-transparent rounded-lg relative w-full h-64">
          <div class="h-full bg-gray-500 rounded-lg"></div>
        </div>
      </div>
      <div className=" flex justify-between  flex-wrap  py-5  w-full">
        <div class="flex flex-col text-start lg:w-2/3 w-full lg:pr-10 gap-2 animate-pulse ">
          <div class="lg:text-3xl text-2xl h-6 lg:w-80 bg-gray-500 mb-2 "></div>{" "}
          <div className=" flex gap-2 items-center mb-4">
            <div class="lg:text-3xl text-2xl h-3 lg:w-20 bg-gray-500 mb-2"></div>
            <div className="h-2 w-2 bg-gray-500 mb-2 rounded-full"></div>
            <div class="lg:text-3xl text-2xl h-3 w-20 bg-gray-500 mb-2"></div>
          </div>{" "}
          {/* <div class="flex flex-row text-lg gap-1 mb-2 h-4 bg-gray-500 "></div> */}
          <div className=" w-full">
            <div class="flex flex-row flex-wrap mb-3 h-2 bg-gray-500 rounded "></div>
            <div class="flex flex-row flex-wrap mb-3 h-2 bg-gray-500 rounded w-3/4"></div>
            <div class="flex flex-row flex-wrap mb-3 h-2 bg-gray-500 rounded w-3/5"></div>
          </div>
          <div class="h-24 bg-gray-500 mb-3 rounded"></div>
          <div class="h-6 bg-gray-500 mb-2"></div>
          <div class="h-24 bg-gray-500 mb-3 rounded"></div>
        </div>
        <div class="lg:flex hidden flex-col text-start lg:w-1/3  gap-2 animate-pulse">
          <div className=" flex flex-col">
            <div class="lg:text-3xl text-2xl h-3 w-28 bg-gray-500 mb-4"></div>
            <div className=" flex flex-wrap gap-3">
              <div class="lg:text-3xl text-2xl h-5 w-20 bg-gray-500 mb-2 rounded"></div>
              <div class="lg:text-3xl text-2xl h-5 w-20 bg-gray-500 mb-2 rounded"></div>
              <div class="lg:text-3xl text-2xl h-5 w-20 bg-gray-500 mb-2 rounded"></div>
            </div>
          </div>{" "}
          {/* <div class="flex flex-row text-lg gap-1 mb-2 h-4 bg-gray-500 "></div> */}
          <div class="flex flex-row flex-wrap mb-3 h-10 bg-gray-500 rounded "></div>
          <div class="h-24 bg-gray-500 mb-3 rounded"></div>
          <div class="h-6 bg-gray-500 mb-2 "></div>
          <div class="h-24 bg-gray-500 mb-3 rounded"></div>
        </div>
      </div>
    </div>
  );
};

export default MovieSkelton;
