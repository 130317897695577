import React, { useState } from "react";
import "./Genres.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../footer/Footer";
import stringSimilarity from "string-similarity";
import Metadata from "../metadata/MetaData";
import Navbar from "../headers/Navbar";

function Genres() {
  const [movieDetails, setMovieDetails] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  // search functionality
  const handleSearch = () => {
    const apiUrlWithQuery = `${process.env.REACT_APP_BASE_URL}/cinemate/search/movie/?title=${searchQuery}`;
    // console.log(apiUrlWithQuery);
    axios
      .get(apiUrlWithQuery)
      .then((response) => {
        // console.log(response);
        // console.log(response.data.movie);
        if (
          Array.isArray(response.data.results.movie) &&
          response.data.results.movie.length === 0
        ) {
          // console.log("Movie not found");
          navigate("/movie-not-found");
        } else {
          setMovieDetails(response.data.results);
          navigate("/moviespage", {
            state: { movieDetails: response.data.results },
          });
        }
      })
      .catch((error) => {
        console.log("Error fetching Movie Details:", error);
        setMovieDetails(null);
      });
  };
  // Auto complete the movie
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);
    // console.log("input value", inputValue);
    // Introduce a delay before making the autocomplete request
    setTimeout(() => {
      if (inputValue && inputValue.length >= 3) {
        const apiUrlWithQuery = `${process.env.REACT_APP_BASE_URL}/cinemate/search/movie/?title=${inputValue}`;
        // console.log("Api req with url", apiUrlWithQuery);

        axios
          .get(apiUrlWithQuery)
          .then((response) => {
            // console.log('Api response', response.data);
            if (
              Array.isArray(response.data.results) &&
              response.data.results.length > 0
            ) {
              // Extract movie titles from the API response
              const movieTitles = response.data.results.map(
                (movie) => movie.title
              );
              const sortedMovieTitles = stringSimilarity
                .findBestMatch(inputValue, movieTitles)
                .ratings.sort((a, b) => b.rating - a.rating)
                .map((match) => match.target);

              // console.log("Sorted Movie titles", sortedMovieTitles);
              setSuggestions(sortedMovieTitles);
            } else {
              setSuggestions([]);
            }
          })
          .catch((error) => {
            console.log("Error fetching autocomplete suggestions:", error);
            setSuggestions([]);
          });
      } else {
        setSuggestions([]);
      }
    }, 500);
  };

  const genres = [
    {
      genre: "romance",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5262/1535262-a-fbabfaf1176e",
      title: "Romance Genre",
    },
    {
      genre: "drama",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5285/1535285-a-88035ca1ae69",
      title: "Drama Genre",
    },
    {
      genre: "family",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5284/1535284-a-656c6b45a905",
      title: "family",
    },
    {
      genre: "comedy",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5292/1535292-a-5739f9c84b63",
      title: "comedy",
    },
    {
      genre: "action",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5302/1535302-a-e90748391e0d",
      title: "action",
    },
    {
      genre: "mystery",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5269/1535269-a-e0ed0b72ebe7",
      title: "mystery",
    },
    {
      genre: "thriller",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5246/1535246-a-27373cc1a222 ",
      title: "thriller",
    },
    {
      genre: "adventure",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5301/1535301-a-9bb68bcd147c",
      title: "adventure",
    },
    {
      genre: "animation",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5299/1535299-a-e6296badeb14 ",
      title: "animation",
    },
    {
      genre: "fiction",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5259/1535259-a-6e0b7daffb29 ",
      title: "fiction",
    },
    {
      genre: "horror",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5279/1535279-a-c92b487cb711",
      title: "horror",
    },
    {
      genre: "musical",
      imageUrl:
        "https://img1.hotstarext.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/5270/1535270-a-6a85b09721ab",
      title: "musical",
    },
  ];
  const genreStyles = {
    romance: {
      background:
        "linear-gradient(to right, rgb(231, 112, 226), rgb(173, 45, 105))",
      boxShadow: "rgba(194, 59, 171, 0.3) 0px 10px 40px -10px",
    },
    drama: {
      background:
        "linear-gradient(to right, rgb(112, 146, 231), rgb(45, 87, 173))",
      boxShadow: "rgba(59, 105, 194, 0.3) 0px 10px 40px -10px",
    },
    family: {
      background:
        "linear-gradient(to right, rgb(112, 231, 174), rgb(45, 173, 105))",
      boxShadow: "rgba(59, 194, 105, 0.3) 0px 10px 40px -10px",
    },
    comedy: {
      background:
        "linear-gradient(to right, rgb(231, 207, 112), rgb(173, 122, 45))",
      boxShadow: "rgba(194, 146, 59, 0.3) 0px 10px 40px -10px",
    },
    action: {
      background:
        "linear-gradient(to right, rgb(231, 112, 112), rgb(173, 45, 45))",
      boxShadow: "rgba(194, 59, 59, 0.3) 0px 10px 40px -10px",
    },
    mystery: {
      background:
        "linear-gradient(to right, rgb(112, 231, 231), rgb(45, 173, 173))",
      boxShadow: "rgba(59, 194, 194, 0.3) 0px 10px 40px -10px",
    },
    thriller: {
      background:
        "linear-gradient(to right, rgb(174, 112, 231), rgb(105, 45, 173))",
      boxShadow: "rgba(105, 59, 194, 0.3) 0px 10px 40px -10px",
    },
    adventure: {
      background:
        "linear-gradient(to right, rgb(231, 231, 112), rgb(173, 173, 45))",
      boxShadow: "rgba(194, 194, 59, 0.3) 0px 10px 40px -10px",
    },
    animation: {
      background:
        "linear-gradient(to right, rgb(112, 231, 146), rgb(45, 173, 87))",
      boxShadow: "rgba(59, 194, 105, 0.3) 0px 10px 40px -10px",
    },
    fiction: {
      background:
        "linear-gradient(to right, rgb(112, 146, 231), rgb(45, 87, 173))",
      boxShadow: "rgba(59, 105, 194, 0.3) 0px 10px 40px -10px",
    },
    horror: {
      background:
        "linear-gradient(to right, rgb(231, 112, 112), rgb(173, 45, 45))",
      boxShadow: "rgba(194, 59, 59, 0.3) 0px 10px 40px -10px",
    },
    musical: {
      background:
        "linear-gradient(to right, rgb(231, 112, 226), rgb(173, 45, 105))",
      boxShadow: "rgba(194, 59, 171, 0.3) 0px 10px 40px -10px",
    },
  };
  const seoTitle = "All Genres";
  const seoDescription = "Your streaming guide for Movies";
  const pageTitle = "All Genres";
  const pageDescription =
    "Discover a world of endless entertainment as you navigate through an extensive collection of movies";

  return (
    <div className=" bg-black w-full min-h-screen flex flex-col">
      <Metadata
        seoTitle={`${seoTitle} | Cinefinder 4U`}
        seoDescription={seoDescription}
        pageTitle={pageTitle}
        pageDescription={pageDescription}
      />
      <Navbar bgColor={"bg-gray-900"} />

      {/* genres listed section */}
      <div className="flex-grow">
        <div className="flex flex-col h-full overflow-auto">
          <section className="text-gray-600 body-font">
            <div className="container px-5 py-10 mx-auto">
              <div className="flex flex-col text-center w-full mb-5 md:mb-20">
                <h1 className="text-4xl text-gray-200 font-Headings tracking-tight  sm:text-4xl md:text-5xl lg:text-4xl">
                  POPULAR <span className="text-indigo-600">GENRES</span>
                </h1>
              </div>
              <div className="flex flex-wrap -m-4">
                {genres.map((genreData) => (
                  <div
                    className="lg:w-1/4 w-1/2 p-4 card-container"
                    key={genreData.genre}
                  >
                    <Link to={`/genres/${genreData.genre}`}>
                      <div
                        className="aspect-w-3 aspect-h-3 rounded-lg w-full h-[150px] relative flex items-center justify-center"
                        style={genreStyles[genreData.genre]}
                      >
                        <div className="absolute bottom-5 left-5 text-white font-semibold capitalize text-xl">
                          {genreData.genre}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Genres;
