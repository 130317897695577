import React, { useState, useEffect, useContext } from "react";
import { Tab } from "@headlessui/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FaPlay } from "react-icons/fa";

import "./Movie.css";
import Footer from "../footer/Footer";
import UserRating from "./UserRating";
import UserReview from "./UserReview";
import Metadata from "../metadata/MetaData";
import ReviewList from "./ReviewList";
import CastAvatar from "./CastAvatar";
import ReccomandMovie from "./ReccomandMovie";
import Platform from "./Platform";
import { AuthContext } from "../authContext/AuthContext";
import Navbar from "../headers/Navbar";
import MovieSkelton from "./MovieSkelton";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function Movie() {
  const navigate = useNavigate();

  const { movie_id } = useParams();
  const { isLoggedIn } = useContext(AuthContext);
  const accessToken = localStorage.getItem("accessToken");

  const [fetchedMovieDetails, setFetchedMovieDetails] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0); //for tabs index

  // user rating section
  const [userRating, setUserRating] = useState(0);
  const [userReview, setUserReview] = useState("");
  const [reviews, setReviews] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); //for showing error messages in post review
  const [isRatingSelected, setIsRatingSelected] = useState(false);

  const [loading, setLoading] = useState(true); //main movie details loader
  const [reviewLoading, setReviewLoading] = useState(false); //while loading user rating
  const [posting, setPosting] = useState(false); //loading while posting review

  useEffect(() => {
    if (!movie_id) return;

    const onGetMovie = async () => {
      try {
        const url = `${process.env.REACT_APP_BASE_URL}/cinemate/movie_page/?movie_id=${movie_id}`;
        const response = await axios.get(url);
        setFetchedMovieDetails(response.data);
      } catch (error) {
        console.error("Error fetching movie details:", error);
      } finally {
        setLoading(false);
      }
    };

    onGetMovie();
  }, [movie_id]);

  // Recommendation movie click function
  const handleRecommendationClick = async (id) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/cinemate/movie_page/?movie_id=${id}`;
      const response = await axios.get(url);
      setFetchedMovieDetails(response.data);
      navigate(`/movie/${id}`);
    } catch (error) {
      console.error("Error fetching recommended movie details:", error);
    } finally {
      setLoading(false);
    }
  };

  const onRatingChange = (rating) => {
    setUserRating(rating);
    setIsRatingSelected(true);
  };

  const onReviewChange = (e) => {
    setUserReview(e.target.value);
  };

  const onPostReview = async () => {
    setPosting(true);

    // Check if the user is logged in
    if (!isLoggedIn()) {
      // Pass the current path as state to the login route
      navigate("/signin", { state: { from: window.location.pathname } });
      return;
    }
    try {
      if (userReview.trim() === "") {
        setErrorMessage("Please enter a review before posting.");
        return;
      } else if (!isRatingSelected) {
        setErrorMessage("Please select a rating before posting.");
        return;
      }
      setErrorMessage("");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/cinemate/add_reviews/`,
        {
          movie_id: movie_id,
          comment: userReview,
          star_rating: `R${userRating}`,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Review posted successfully!");

        // Fetch the latest reviews after posting
        await fetchReviews();
        setSelectedIndex(2);
      }

      setUserReview("");
      setUserRating(0);
      setIsRatingSelected(false);
    } catch (error) {
      console.error("Error posting review:", error);
    } finally {
      setPosting(false);
    }
  };

  const fetchReviews = async () => {
    setReviewLoading(true);
    try {
      if (movie_id) {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/cinemate/add_reviews/?movie_id=${movie_id}&page=1`
        );
        setReviews(response.data.values);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setReviewLoading(false);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [movie_id]);

  return (
    <div className="bg-black flex flex-col">
      <Metadata
        seoTitle={`${
          (fetchedMovieDetails && fetchedMovieDetails.values.title) || movie_id
        } | Cinefinder4U`}
        seoDescription={movie_id}
        // pageTitle={heading}
        // pageDescription={pageDescription}
      />
      <Navbar bgColor="bg-black" />
      {/* movie content section */}
      <section className="text-gray-400 py-10 bg-gray-900 body-font  ">
        {loading ? (
          //  loader while data is loading
          <MovieSkelton />
        ) : (
          <div className="lg:px-20 px-5 ">
            {/* <div className=" flex flex-col  justify-center py-1 px-16 "> */}

            <div className="flex lg:flex-row flex-col relative w-full rounded-lg ">
              <div className="">
                <img
                  src={
                    fetchedMovieDetails?.values.image ||
                    fetchedMovieDetails?.values.image2 ||
                    "../assets/images/alt-image.jpg"
                  }
                  alt="movie_image"
                  loading="lazy"
                  onError={(e) => {
                    if (
                      e.target.src.includes(
                        fetchedMovieDetails?.values.image
                      ) &&
                      fetchedMovieDetails?.values.image2
                    ) {
                      e.target.src = fetchedMovieDetails?.values.image2;
                    } else {
                      e.target.src = "../../assets/images/alt-image.jpg";
                    }
                  }}
                  className=" w-full h-full"
                />
              </div>
              {fetchedMovieDetails?.values.trailer_link_ &&
                fetchedMovieDetails.values.trailer_link_.trim() !== "" && (
                  <div
                    className="w-full flex flex-col bg-transparent  relative  lg:h-full h-1/2"
                    // style={{
                    //   width: "100%",
                    //   height: "100%",
                    // }}
                  >
                    <iframe
                      title="YouTube video player"
                      width="100%"
                      height={500}
                      src={`${fetchedMovieDetails.values.trailer_link_}?autoplay=1&mute=1&controls=0&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&disablekb=1&widgetid=1`}
                      frameBorder="0"
                      allowFullScreen="1"
                      className=""
                    ></iframe>
                  </div>
                )}
            </div>

            <div className=" flex justify-between  flex-wrap  py-5">
              <div className="flex flex-col text-start lg:w-2/3 lg:pr-10 gap-2 ">
                <div className="lg:text-3xl text-2xl text-white font-semibold capitalize pb-2">
                  {fetchedMovieDetails.values.title}{" "}
                </div>
                <div className=" flex flex-row text-lg gap-1 text-gray-400 mb-2 font-semibold">
                  {/* {fetchedMovieDetails.audience && (
                      <div>
                        {fetchedMovieDetails.audience}{" "}
                        <span className=" opacity-40"> •</span>
                      </div>
                    )} */}
                  {fetchedMovieDetails.values.duration && (
                    <div>
                      {fetchedMovieDetails.values.duration}
                      <span className=" opacity-40"> •</span>
                    </div>
                  )}
                  {fetchedMovieDetails.values.user_rating && (
                    <div className="flex gap-1 items-center">
                      <img
                        src="../../assets/images/imdb.webp"
                        alt="imdb logo"
                        style={{
                          width: "90%",
                          height: "70%",
                          display: "block",
                        }}
                      />
                      {fetchedMovieDetails.values.user_rating}
                    </div>
                  )}
                </div>
                <div className="flex flex-row flex-wrap mb-3 gap-2">
                  {fetchedMovieDetails.watch_links.map((link, index) => (
                    <div key={index} className="flex flex-col ">
                      {/* <div className="mb-1">
                          <span
                            className={`px-2 py-1 text-xs font-bold rounded text-start ${
                              link.watch_type === "Rent"
                                ? "bg-yellow-200 text-yellow-800"
                                : "bg-indigo-200 text-indigo-800"
                            }`}
                          >
                            {link.watch_type}
                          </span>
                        </div> */}
                      <Link
                        to={link.watch_link}
                        target="_blank"
                        className={`${
                          link.watch_type === "Rent"
                            ? "bg-yellow-500 hover:bg-yellow-600 text-black"
                            : "bg-indigo-600 hover:bg-indigo-700 text-white"
                        } pr-3 pl-3 py-3 font-semibold rounded-3xl  capitalize inline-flex items-center justify-center ${
                          link.watch_link === ""
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        disabled={link.watch_link === ""}
                      >
                        <FaPlay className="mr-2" />
                        {link.watch_link === ""
                          ? "Not available"
                          : ` ${link.watch_type} on ${link.host} ${
                              link.price ? `- ${link.price}` : ""
                            }`}
                      </Link>
                    </div>
                  ))}
                </div>
                {fetchedMovieDetails.values.story && (
                  <div className=" capitalize text-lg leading-8 text-[#a2adb3]">
                    {fetchedMovieDetails.values.story}
                  </div>
                )}

                {fetchedMovieDetails.values.director && (
                  <div className=" flex flex-col justify-start">
                    <h2 className=" font-semibold text-lg  text-[#a2adb3] opacity-50">
                      Directed By
                    </h2>
                    <div className="capitalize text-lg leading-8 text-[#a2adb3]">
                      {fetchedMovieDetails.values.director}
                    </div>
                  </div>
                )}
              </div>

              <div className=" flex flex-col flex-wrap lg:w-1/3 w-full">
                {fetchedMovieDetails.values.genre?.length > 0 && (
                  <div className="flex flex-col justify-start mb-4">
                    <h2 className="font-semibold text-lg mb-2 text-[#a2adb3] opacity-50">
                      Genre
                    </h2>
                    <div className="flex gap-2 flex-wrap">
                      {fetchedMovieDetails.values.genre.map((genre, index) => (
                        <span
                          key={index}
                          className="flex bg-[#252e34] text-[#a2adb3] px-2 py-1 rounded-lg capitalize font-semibold"
                        >
                          {genre}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                {fetchedMovieDetails.values.release_year && (
                  <div className=" flex flex-col justify-start mb-3">
                    <h2 className=" font-semibold text-lg mb-1 text-white opacity-50">
                      Release Date
                    </h2>

                    <p className="text-[#b7c3c9] font-semibold">
                      {fetchedMovieDetails.values.release_year}
                    </p>
                  </div>
                )}
                <div className=" flex flex-col lg:justify-start  w-full">
                  <h2 className=" font-semibold text-xl mb-2 text-yellow-500">
                    Post Review
                  </h2>
                  <UserRating
                    onRatingChange={onRatingChange}
                    userRating={userRating}
                  />
                  <div className="error-message text-red-500">
                    {errorMessage}
                  </div>

                  <UserReview
                    onReviewChange={onReviewChange}
                    onPostReview={onPostReview}
                    userReview={userReview}
                    reviews={reviews}
                    isRatingSelected={isRatingSelected}
                    posting={posting}
                  />
                </div>
              </div>
            </div>
            <div className=" w-full  ">
              <Tab.Group
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
              >
                <Tab.List className="flex lg:space-x-4 space-x-2 py-4 border-t border-gray-500 ">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        " py-1  leading-5 font-medium  rounded-lg lg:text-lg",
                        "focus:outline-none",
                        selected
                          ? " text-[#a2adb3] underline underline-offset-8"
                          : "text-[#a2adb3]  hover:opacity-90 opacity-40"
                      )
                    }
                  >
                    Similar
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        " py-1  leading-5 font-medium  rounded-lg lg:text-lg",
                        "focus:outline-none",
                        selected
                          ? " text-[#a2adb3] underline underline-offset-8"
                          : "text-[#a2adb3]  hover:opacity-90 opacity-40"
                      )
                    }
                  >
                    Cast
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        " py-1  leading-5 font-medium  rounded-lg lg:text-lg",
                        "focus:outline-none",
                        selected
                          ? " text-[#a2adb3] underline underline-offset-8"
                          : "text-[#a2adb3]  hover:opacity-90 opacity-40"
                      )
                    }
                  >
                    User Rating
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        " py-1  leading-5 font-medium  rounded-lg lg:text-lg",
                        "focus:outline-none",
                        selected
                          ? " text-[#a2adb3] underline underline-offset-8"
                          : "text-[#a2adb3]  hover:opacity-90 opacity-40"
                      )
                    }
                  >
                    Platforms
                  </Tab>
                </Tab.List>
                <Tab.Panels className="mt-1">
                  <Tab.Panel className=" rounded-lg shadow">
                    <ReccomandMovie
                      fetchedMovieDetails={fetchedMovieDetails}
                      // handleImageClick={handleImageClick}
                      onRecommendationClick={handleRecommendationClick}
                    />
                  </Tab.Panel>
                  <Tab.Panel className="  rounded-lg shadow">
                    <CastAvatar fetchedMovieDetails={fetchedMovieDetails} />
                  </Tab.Panel>
                  <Tab.Panel className=" rounded-lg shadow">
                    <ReviewList
                      reviews={reviews}
                      reviewLoading={reviewLoading}
                    />
                  </Tab.Panel>
                  <Tab.Panel className=" rounded-lg shadow">
                    <Platform fetchedMovieDetails={fetchedMovieDetails} />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>

            {/* </div> */}
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
}
export default Movie;
