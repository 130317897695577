import React, { useRef } from "react";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import SwiperSlider from "./SwiperSlider";
import Metadata from "../metadata/MetaData";

const TicketHero = ({ searchResult }) => {
  const ticketNewRef = useRef(null);
  const bookMyShowRef = useRef(null);

  const handlePrev = (ref) => {
    if (ref.current && ref.current.swiper) {
      ref.current.swiper.slidePrev();
    }
  };

  const handleNext = (ref) => {
    if (ref.current && ref.current.swiper) {
      ref.current.swiper.slideNext();
    }
  };

  return (
    <div className="bg-black ">
      <div className="container mx-auto lg:px-20 px-5 lg:py-10 flex flex-col">
        {searchResult.ticket_new_vals.length > 0 && (
          <div className="flex flex-col mb-5 ">
            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center text-blue-500 text-[24px] gap-2 font-semibold mb-4">
                Available On
                <span>
                  <img
                    src="../assets/images/ticketnew.png"
                    alt=""
                    width={100}
                  />
                </span>
              </div>
            </div>
            <SwiperSlider
              sliderRef={ticketNewRef}
              data={searchResult.ticket_new_vals}
              // imageHeight="250px"
            />
          </div>
        )}
        {searchResult.book_my_show_vals.length > 0 && (
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center text-blue-500 text-[24px] gap-2 font-semibold mb-4">
                Available On
                <span>
                  <img
                    src="https://cdn.shopper.com/images/store-logos/BookMyShow-Logo-1547646747502.png"
                    alt=""
                    width={100}
                    height={50}
                  />
                </span>
              </div>
            </div>
            <SwiperSlider
              sliderRef={bookMyShowRef}
              data={searchResult.book_my_show_vals}
              // imageHeight="400px"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketHero;
