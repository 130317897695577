import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import Footer from "../footer/Footer";
import axios from "axios";
import stringSimilarity from "string-similarity";
import Metadata from "../metadata/MetaData";
import AddStera from "../addStera/AddStera";
import AddSteraBanner from "../addStera/AddSteraBanner";

function GenreTemplate({ genre, heading }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [movies, setMovies] = useState([]);
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  //   const genre = "action";

  const [noMoviesFound, setNoMoviesFound] = useState(false);
  const [page, setPage] = useState(1);
  const [totalMoviesCount, setTotalMoviesCount] = useState(0);

  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // for search results only
  const searchMovies = () => {
    setIsLoading(false);
    setMovies([]); // Clear previous results
    fetch(
      `${process.env.REACT_APP_BASE_URL}/cinemate/genre_filter/?genre=${genre}&title=${searchQuery}`
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("Search API response", data);

        if (data.values.length === 0) {
          setNoMoviesFound(true);
        } else {
          setMovies(data.values); // Set new search results
          setNoMoviesFound(false);
        }

        setTotalMoviesCount(data.count);
      })
      .catch((error) => {
        console.error("Error fetching movies:", error);
        setIsLoading(false);
      });
  };
  // console.log(title);

  // auto complete part

  // for pagination
  const [searchedMovies, setSearchedMovies] = useState([]);
  const [reachedBottom, setReachedBottom] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isLoading
    ) {
      return;
    }
    handleLoadMore();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);
  useEffect(() => {
    // Add an event listener for the scroll event
    window.addEventListener("scroll", handleScroll);

    // Check if the user is already at the bottom when the component mounts
    handleScroll();

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchMoviesByURL = (url) => {
    setIsLoading(true);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Api response", data);

        if (data.values.length === 0) {
          setNoMoviesFound(true);
        } else {
          // Check if there's a search query
          if (title) {
            // If there's a search query, update searchedMovies with the new results
            setSearchedMovies(data.values);
            setMovies([]); // Clear the previously loaded movies
          } else {
            // If no search query, append new movies to the existing list
            setMovies([...movies, ...data.values]);
          }

          setNoMoviesFound(false);
          setNextPageUrl(data.next_page);
        }

        setTotalMoviesCount(data.count);
        setPrevPageUrl(data.previous_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching movies:", error);
        setIsLoading(false);
      });
  };

  const handleLoadMore = () => {
    if (nextPageUrl) {
      setPage(page + 1); // Increment the page
      setIsLoading(true);

      fetchMoviesByURL(nextPageUrl);
    }
  };
  useEffect(() => {
    if (!initialLoadComplete) {
      // Initial load has not occurred, trigger it
      handleLoadMore();
      setInitialLoadComplete(true); // Set the initial load flag
    } else if (reachedBottom) {
      // Subsequent loads when the user reaches the bottom
      handleLoadMore();
    }
  }, [reachedBottom, initialLoadComplete]);

  useEffect(() => {
    fetchMoviesByURL(
      `${process.env.REACT_APP_BASE_URL}/cinemate/genre_filter/?genre=${genre}&page=${page}`
    );
    setInitialLoadComplete(true);
  }, [title, genre, page, isSearching]);

  return (
    <div className="bg-gray-900 w-full min-h-screen flex flex-col">
      <Metadata
        seoTitle={`${heading} | Cinefinder4U `}
        seoDescription={heading}
        // pageTitle={heading}
        // pageDescription={pageDescription}
      />
      <header className=" z-50">
        <nav
          className="flex items-center justify-between py-2 lg:px-8  top-0 w-full bg-black"
          aria-label="Global"
        >
          <div className="flex">
            <Link to={"/"} className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-12 w-auto"
                src="../assets/images/logo.png"
                alt="logo"
              />
            </Link>
          </div>
          <div className="text-center text-white flex flex-col items-center">
            <h1 className="lg:text-4xl text-2xl text-gray-200 font-Headings tracking-tight text-center ">
              {heading} <span className="text-indigo-600">GENRE</span>
            </h1>
            <p className="text-white mt-1 text-center">
              Showing <span className="text-indigo-600">{movies.length}</span>{" "}
              out of <span className="text-indigo-600">{totalMoviesCount}</span>{" "}
              movies
            </p>
          </div>

          <div className="lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="hidden lg:flex lg:gap-x-12">
            <Link
              to={"/genres"}
              className="text-sm font-semibold leading-6 text-white bg-indigo-600 px-4 py-2 rounded-lg hover:bg-indigo-500 focus:ring focus:ring-indigo-400"
            >
              All Genres{" "}
            </Link>
          </div>
        </nav>

        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black bg-opacity-80 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            {/* Mobile menu content */}
            <div className="flex items-center justify-between">
              {/* Logo */}
              <Link to={"/"} className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-10 w-auto"
                  src="../assets/images/logo.png"
                  alt=""
                />
              </Link>
              {/* Close button */}
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              {/* <div className="relative flex">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleInputChange}
                  placeholder="Search for a movie . . ."
                  className={`rounded-l-lg bg-gray-800 p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-offset-gray-800 block sm:block w-54`}
                />
                <button
                  onClick={() => {
                    setIsSearching(true);
                    searchMovies();
                  }}
                  className="bg-indigo-600 text-white p-2 rounded-l-none rounded-r-lg hover:bg-indigo-800 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
                {suggestions.length > 0 && searchQuery && (
                  <div className="absolute bg-white border border-gray-300 mt-10 ml-0 rounded shadow-lg w-95 max-h-40 overflow-y-auto">
                    <ul>
                      {suggestions.map((title, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setSearchQuery(title);
                            setSuggestions([]);
                          }}
                          className="px-4 py-2 cursor-pointer hover:bg-indigo-100 text-left"
                        >
                          {title}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div> */}
              <div className="space-y-2 py-6">
                <Link
                  to={"/genres"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                >
                  All Genres{" "}
                </Link>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div className="flex-grow container mx-auto ">
        {/* <AddStera /> */}
        {/* <AddStera2 /> */}
        <div className="flex flex-col h-full">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="py-5 lg:px-5 px-2">
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                {movies.map((movie) => (
                  <div key={movie.id} className="">
                    <Link
                      to={`/movie/${movie.id}`}
                      className="flex flex-col justify-center cursor-pointer "
                    >
                      <div className="relative group">
                        <img
                          src={movie.image || "../assets/images/alt-image.jpg"}
                          alt="movie_image"
                          loading="lazy"
                          onError={(e) => {
                            if (
                              e.target.src.includes(movie.image) &&
                              movie.image2
                            ) {
                              e.target.src = movie.image2;
                            } else {
                              e.target.src =
                                "../../assets/images/alt-image.jpg";
                            }
                          }}
                          className="h-96 w-full rounded flex justify-center items-center object-cover transition-transform duration-300 ease-in-out group-hover:scale-100"
                        />
                        {movie.user_rating && (
                          <div className="absolute bottom-2 right-2 bg-indigo-500 text-white px-2 py-1 rounded z-10">
                            {movie.user_rating}
                          </div>
                        )}
                      </div>
                      <div className="text-white text-md flex flex-col font-semibold capitalize w-full">
                        <div
                          className="text-center line-clamp-1"
                          title={movie.title}
                        >
                          {movie.title}
                        </div>
                        {movie.release_year > 0 && (
                          <div className="text-indigo-600 text-center">
                            ({movie.release_year})
                          </div>
                        )}
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
function Loader() {
  const containerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };
  return (
    <div style={containerStyles}>
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default GenreTemplate;
