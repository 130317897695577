import React from "react";
import { Helmet } from "react-helmet-async";

const Metadata = ({
  seoTitle,
  seoDescription,
  pageTitle,
  pageDescription,
  type,
  image,
}) => {
  return (
    <Helmet>
      <title>{seoTitle}</title>
      <meta name="description" content={"Your streaming guide for Movies"} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={pageTitle} />
      <meta
        property="og:description"
        content={"Your streaming guide for Movies"}
      />
      <meta property="og:image" content={image} />
      <meta property="og:image:url" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta
        name="twitter:description"
        content={
          "Just rate some movies that you've seen, and it'll give you personalized recommendations that you can save to your watchlist"
        }
      />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default Metadata;
