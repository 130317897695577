import "./App.css";
import ScrollTop from "./components/headers/ScrollTop";
import Footer from "./components/footer/Footer";
import Action from "./components/genres/Action";
import Adventure from "./components/genres/Adventure";
import Animation from "./components/genres/Animation";
import Comedy from "./components/genres/Comedy";
import Drama from "./components/genres/Drama";
import Family from "./components/genres/Family";
import Fiction from "./components/genres/Fiction";
import Genres from "./components/genres/Genres";
import Horror from "./components/genres/Horror";
import Musical from "./components/genres/Musical";
import Mystery from "./components/genres/Mystery";
import Romance from "./components/genres/Romance";
import Thriller from "./components/genres/Thriller";
import Hero from "./components/headers/Hero";
import { Routes, Route } from "react-router-dom";
import Movie from "./components/movies/Movie";
import MoviesPage from "./components/movies/MoviesPage";
import NotFound from "./components/movieNotFound/NotFound";
import Privacy from "./components/footer/Privacy";
import Terms from "./components/footer/Terms";
import TicketBooking from "./components/ticketBooking/TicketBookingHome";
import FreeMoviesHome from "./components/freeMovies/FreeMoviesHome";
import Channel from "./components/freeMovies/Channel";
import AllChannels from "./components/freeMovies/AllChannels";
import SignIn from "./components/authForms/SignIn";
import SignUp from "./components/authForms/SignUp";
import { AuthProvider } from "./components/authContext/AuthContext";
import UpComingHome from "./components/upComing/UpComingHome";

function App() {
  return (
    <div>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/genres" element={<Genres />} />
          <Route path="/privacy" element={<Privacy />} />{" "}
          <Route path="/terms-conditions" element={<Terms />} />
          {/* Genres Routes */}
          <Route path="/genres/romance" element={<Romance />} />
          <Route path="/genres/drama" element={<Drama />} />
          <Route path="/genres/family" element={<Family />} />
          <Route path="/genres/comedy" element={<Comedy />} />
          <Route path="/genres/action" element={<Action />} />
          <Route path="/genres/mystery" element={<Mystery />} />
          <Route path="/genres/thriller" element={<Thriller />} />
          <Route path="/genres/adventure" element={<Adventure />} />
          <Route path="/genres/animation" element={<Animation />} />
          <Route path="/genres/fiction" element={<Fiction />} />
          <Route path="/genres/horror" element={<Horror />} />
          <Route path="/genres/musical" element={<Musical />} />
          <Route path="/movie/:movie_id" element={<Movie />} />
          <Route path="/moviespage" element={<MoviesPage />} />
          <Route path="/movie-not-found" element={<NotFound />} />
          <Route path="/ticket/home" element={<TicketBooking />} />
          <Route path="/free-movies" element={<FreeMoviesHome />} />
          <Route path="/free-movies/channel/:id" element={<Channel />} />
          <Route path="/free-movies/all-channels" element={<AllChannels />} />
          <Route path="/upcoming-movies" element={<UpComingHome />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
      <ScrollTop />
    </div>
  );
}

export default App;
