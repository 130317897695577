import React, { useEffect, useState } from "react";
import Navbar from "../headers/Navbar";
import Footer from "../footer/Footer";
import { Tab } from "@headlessui/react";
import { FaArrowRightLong } from "react-icons/fa6";

import ImageCard from "./ImageCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomSelect from "../headers/CustomSelect";
import SkeletonLoader from "./SkeltonLoader";
import Metadata from "../metadata/MetaData";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const theatreOptions = [
  { value: "", label: "All" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Tamil", label: "Tamil" },
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
];

const ottOptions = [
  { value: "all", label: "All" },
  { value: "Movies", label: "Movies" },
  { value: "Series", label: "Series" },
];

const UpComingHome = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParam = (param, defaultValue) => {
    const query = new URLSearchParams(location.search);
    return query.get(param) || defaultValue;
  };

  const [selectedValue, setSelectedValue] = useState(
    getQueryParam("language", theatreOptions[0].value)
  );
  const [filter_by, setfilter_by] = useState(
    getQueryParam("filter_by", "Movies")
  );
  const [type, setType] = useState(getQueryParam("type", "theatre"));
  const [page, setPage] = useState(getQueryParam("page", "1"));
  const [data, setData] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!location.search) {
      navigate(
        `/upcoming-movies?type=theatre&page=1&language=${theatreOptions[0].value}`
      );
    }
  }, [location.search, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_BASE_URL
          }/cinemate/upcoming_movies/?type=${type}&page=${page}${
            type === "theatre"
              ? `&language=${selectedValue}`
              : `&filter_by=${filter_by}`
          }`
        );
        const data = await response.json();
        console.log("Fetched data:", data);
        setData(data.values || {});
        setNextPage(data.next_page || null);
        setPreviousPage(data.previous_page || null);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedValue, type, page, filter_by]);

  const handleTabChange = (index) => {
    const newType = index === 0 ? "theatre" : "ott";
    setType(newType);
    setPage("1");
    if (newType === "theatre") {
      setSelectedValue(theatreOptions[0].value);
      setfilter_by(""); // Default filter for theatre tab
      navigate(
        `/upcoming-movies?type=${newType}&page=1&language=${theatreOptions[0].value}`
      );
    } else {
      setSelectedValue(null);
      setfilter_by("all"); // Default filter for ott tab
      navigate(`/upcoming-movies?type=${newType}&page=1&filter_by=all`);
    }
  };

  const handleChange = (selectedOption) => {
    if (type === "theatre") {
      const newValue = selectedOption.value;
      setSelectedValue(newValue);
      navigate(`/upcoming-movies?type=${type}&page=1&language=${newValue}`);
    } else {
      const newFilter = selectedOption.value;
      setfilter_by(newFilter);
      navigate(`/upcoming-movies?type=${type}&page=1&filter_by=${newFilter}`);
    }
  };

  const handlePagination = (direction) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const newPage = direction === "next" ? nextPage : previousPage;
    if (newPage) {
      setPage(newPage);
      navigate(
        `/upcoming-movies?type=${type}&page=${newPage}${
          type === "theatre"
            ? `&language=${selectedValue}`
            : `&filter_by=${filter_by}`
        }`
      );
    }
  };

  const currentOptions = type === "theatre" ? theatreOptions : ottOptions;

  return (
    <div className="bg-gray-800 w-full flex flex-col min-h-screen">
      <Metadata seoTitle={`Upcoming Releases | Cinefinder4U `} />
      <Navbar bgColor={"bg-black"} />
      <div className="container mx-auto py-5 px-5 flex-grow">
        <div className=" flex flex-col gap-5">
          <div className=" flex items-center">
            <h2 className=" text-white font-semibold lg:text-3xl text-xl border-s-4 px-2 rounded-md border-blue-600 py-2 capitalize ">
              Upcoming <span className=" ">releases</span>
            </h2>
            <CustomSelect
              options={currentOptions}
              selectedvalue={type === "theatre" ? selectedValue : filter_by}
              handleChange={handleChange}
              borderRadius={"5px"}
              width="100%"
              lgWidth="150px"
              smWidth="110px"
              background={"#374151"}
              border="none"
              color="#3b82f6"
              fontSize="20px"
              fontWeight="700"
              hoverBackground="#1f2937"
            />
          </div>
          <div className=" flex items-center justify-between bg-gray-700 lg:p-5 p-2 rounded-lg">
            <div className="bg-gradient-to-r items-center from-blue-500 via-teal-500 to-pink-500 bg-clip-text text-lg font-extrabold text-transparent text-center select-auto">
              Now Showing
            </div>
            <Link
              to={"/ticket/home"}
              className=" flex items-center gap-1 text-blue-500 font-medium hover:underline transition-transform delay-100 "
            >
              In Cinemas near you
              <span>
                <FaArrowRightLong />
              </span>{" "}
            </Link>
          </div>

          <div>
            <Tab.Group onChange={handleTabChange}>
              <Tab.List className="flex gap-x-2 transition-transform delay-200">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "p-3 font-semibold text-lg transition-all ease-in-out outline-none",
                      selected
                        ? "text-gray-200 border-b-2 border-blue-500"
                        : "text-white hover:opacity-70 opacity-40 border-b-2 border-transparent"
                    )
                  }
                >
                  Theatre
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "p-3 font-semibold text-lg transition-all ease-in-out outline-none ",
                      selected
                        ? "text-gray-200 border-b-2 border-blue-500"
                        : "text-white hover:opacity-70 opacity-40 border-b-2 border-transparent"
                    )
                  }
                >
                  OTT
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-1">
                <Tab.Panel>
                  {loading ? (
                    <SkeletonLoader />
                  ) : (
                    <ImageCard
                      data={data}
                      previousPage={previousPage}
                      nextPage={nextPage}
                      handlePagination={handlePagination}
                      currentPage={page}
                    />
                  )}
                </Tab.Panel>
                <Tab.Panel>
                  {loading ? (
                    <SkeletonLoader />
                  ) : (
                    <ImageCard
                      data={data}
                      previousPage={previousPage}
                      nextPage={nextPage}
                      handlePagination={handlePagination}
                      currentPage={page}
                    />
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>{" "}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default UpComingHome;
