import React from "react";
import Select from "react-select";

const CustomSelect = (props) => {
  return (
    <Select
      options={props.options}
      defaultValue={props.options[0]}
      value={props.options.find(
        (option) => option.value === props.selectedvalue
      )}
      onChange={props.handleChange}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: props.border,
          width: props.width,
          background: props.background,
          fontSize: props.fontSize,
          color: props.color,
          fontWeight: props.fontWeight,
          borderRadius: props.borderRadius,
          boxShadow: state.isFocused ? "none" : baseStyles.boxShadow,
          "&:focus": {
            border: "none",
            boxShadow: "none",
          },
          "@media (min-width: 1024px)": {
            width: props.lgWidth, // Width for larger screens
          },
          "@media (max-width: 640px)": {
            width: props.smWidth, // Width for smaller screens
          },
          "&:hover": {
            background: props.hoverBackground,
          },
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: props.color,
          // fontSize: props.fontSize,
          fontWeight: props.fontWeight,
        }),
        option: (baseStyles, { isSelected, isFocused, data }) => ({
          ...baseStyles,
          fontSize: props.fontSize,
          textAlign: "left",
          borderRadius: "0px",
        }),
      }}
      isSearchable={false}
    />
  );
};

export default CustomSelect;
