import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Footer from "../footer/Footer";
import stringSimilarity from "string-similarity";
import AddStera from "../addStera/AddStera";
import CustomSelect from "../headers/CustomSelect";
import Navbar from "../headers/Navbar";

const MoviesPage = () => {
  const location = useLocation();
  const { movieDetail, inputQuery } = location.state || {};
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // console.log("input query",inputQuery);

  const [title, setTitle] = useState("");
  const [movieDetails, setMovieDetails] = useState(movieDetail);
  // console.log("movieDetails",movieDetail);

  const navigate = useNavigate();
  const options = [
    { value: "News", label: "News" },
    { value: "Documentary", label: "Documentary" },
    { value: "Comedy", label: "Comedy" },
    { value: "Sci-Fi", label: "Sci-Fi" },
    { value: "Mystery", label: "Mystery" },
    { value: "Fantasy", label: "Fantasy" },
    { value: "Horror", label: "Horror" },
    { value: "Romance", label: "Romance" },
    { value: "Crime", label: "Crime" },
    { value: "Music", label: "Music" },
    { value: "Animation", label: "Animation" },
    { value: "Sport", label: "Sport" },
    { value: "Musical", label: "Musical" },
    { value: "Adventure", label: "Adventure" },
    { value: "Action", label: "Action" },
    { value: "War", label: "War" },
    { value: "Thriller", label: "Thriller" },
    { value: "History", label: "History" },
    { value: "Family", label: "Family" },
    { value: "Drama", label: "Drama" },
    { value: "Biography", label: "Biography" },
  ];
  const handleImageClick = (movie) => {
    console.log("clicked on movie", movie);
    navigate(`/movie/${movie.id}`, { state: { movieDetailsss: movie } });
    console.log("movie title", movie.id);
  };

  // search functionality
  const handleSearch = () => {
    const apiUrlWithQuery = `${process.env.REACT_APP_BASE_URL}/cinemate/search/movie/?title=${searchQuery}`;
    // console.log(apiUrlWithQuery);
    axios
      .get(apiUrlWithQuery)
      .then((response) => {
        // console.log(response);
        // console.log(response.data.movie);
        if (
          Array.isArray(response.data.results.movie) &&
          response.data.results.movie.length === 0
        ) {
          // console.log("Movie not found");
          navigate("/movie-not-found");
        } else {
          setMovieDetails(response.data.results);
          navigate(`/romacepage/${searchQuery}`, {
            state: { movieDetails: response.data.results },
          });
        }
      })
      .catch((error) => {
        console.log("Error fetching Movie Details:", error);
        setMovieDetails(null);
      });
  };
  // Auto complete the movie
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);
    // console.log("input value", inputValue);
    // Introduce a delay before making the autocomplete request
    setTimeout(() => {
      if (inputValue && inputValue.length >= 3) {
        const apiUrlWithQuery = `${process.env.REACT_APP_BASE_URL}/cinemate/search/movie/?title=${inputValue}`;
        // console.log("Api req with url", apiUrlWithQuery);

        axios
          .get(apiUrlWithQuery)
          .then((response) => {
            // console.log('Api response', response.data);
            if (
              Array.isArray(response.data.results) &&
              response.data.results.length > 0
            ) {
              // Extract movie titles from the API response
              const movieTitles = response.data.results.map(
                (movie) => movie.title
              );
              // Use string-similarity to find the best matches and sort them by rating
              const bestMatches = stringSimilarity.findBestMatch(
                inputValue,
                movieTitles
              );
              const sortedMovieTitles = bestMatches.ratings
                .sort((a, b) => b.rating - a.rating)
                .map((match) => match.target);

              // Remove duplicates by creating a Set
              const uniqueSuggestions = Array.from(new Set(sortedMovieTitles));

              setSuggestions(uniqueSuggestions);
            } else {
              setSuggestions([]);
            }
          })
          .catch((error) => {
            console.log("Error fetching autocomplete suggestions:", error);
            setSuggestions([]);
          });
      } else {
        setSuggestions([]);
      }
    }, 500);
  };

  return (
    <div className="bg-black w-full min-h-screen flex flex-col">
      <Navbar bgColor={"bg-gray-900"} />
      <div className="flex-grow relative  lg:px-28 px-4 mt-5">
        <div className="flex flex-col gap-2    ">
          {/* <h1 className="text-3xl text-gray-200 text-start   tracking-tight   sm:text-3xl">
            Search results for:{" "}
            <span className="text-indigo-600">"{inputQuery}"</span>
          </h1> */}
          {/* <div className=" flex flex-wrap mb-4">
            <CustomSelect
              options={options}
              borderRadius={"5px"}
              width={"150px"}
              fontWeight={"500"}
              fontSize={"15px"}
            />
          </div>{" "} */}
          {/* <hr></hr> */}
        </div>
        <div className=" flex justify-center items-center ">
          <div className=" grid grid-cols-1 gap-y-10 sm:grid-cols-2 lg:grid-cols-5 xl:gap-x-8 ">
            {movieDetails &&
              movieDetails.map((movie, id) => (
                <>
                  <div
                    key={movie.id}
                    onClick={() => handleImageClick(movie)}
                    className="relative flex flex-col  justify-center items-center cursor-pointer transition-transform duration-200 transform hover:scale-105"
                  >
                    <div className="relative">
                      <img
                        src={
                          movie.image ||
                          movie.image2 ||
                          "../assets/images/alt-image.jpg"
                        }
                        alt="movie_image"
                        loading="lazy"
                        onError={(e) => {
                          if (
                            e.target.src.includes(movie.image) &&
                            movie.image2
                          ) {
                            e.target.src = movie.image2;
                          } else {
                            e.target.src = "../assets/images/alt-image.jpg";
                          }
                        }}
                        className="h-full w-full rounded flex justify-center items-center object-fill object-center lg:h-52 lg:w-44"
                      />
                      {movie.user_rating && (
                        <div className="absolute bottom-2 right-2 bg-indigo-500 text-white px-2 py-1 rounded  z-10">
                          {movie.user_rating}
                        </div>
                      )}
                    </div>
                    <div className="text-white text-md flex flex-col justify-between font-semibold uppercase ">
                      <div className=" text-center">
                        {movie.title}
                      </div>
                      <div className="flex justify-center items-center text-center">
                        {movie.release_year > 0 && (
                          <div className="text-indigo-600 mr-4">
                            ({movie.release_year})
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div
                    key={movie.id}
                    onClick={() => handleImageClick(movie)}
                    className=" relative cursor-pointer"
                  >
                    <div className=" relative bg-gray-700 border border-gray-300 w-full h-0 pb-40 rounded-lg transition-all delay-200 overflow-hidden0">
                      <img
                        src={
                          movie.image_path || "../assets/images/alt-image.jpg"
                        }
                        alt="movie_image"
                        loading="lazy"
                        onError={(e) => {
                          e.target.src = "../assets/images/alt-image.jpg";
                        }}
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          inset: "0px",
                          objectFit: "cover",
                          objectPosition: "center center",
                          color: "transparentF",
                        }}
                        className=" hover:transition-transform hover:delay-300 hover:ease-in text-center"
                      />
                    </div>
                    <div className=" relative pt-5">
                      <div className=" flex justify-between items-start px-3">
                        <div className=" text-lg leading-10 font-semibold text-red-500">
                          {movie.title}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MoviesPage;
