import React from "react";

const CastAvatar = ({ fetchedMovieDetails }) => {
  return (
    <div className=" flex flex-wrap gap-3">
      {fetchedMovieDetails &&
        fetchedMovieDetails.cast_details.map((cast, index) => (
          <div
            key={cast.actor_id}
            className="flex flex-col flex-wrap  p-2 w-[150px] text-left"
          >
            <div className="w-full rounded flex flex-col justify-center  ">
              <img
                src={
                  cast.image ||
                  cast.image2 ||
                  "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352156-stock-illustration-default-placeholder-profile-icon.jpg"
                }
                onError={(e) => {
                  if (e.target.src.includes(cast.image) && cast.image2) {
                    e.target.src = cast.image2;
                  } else {
                    e.target.src =
                      "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352156-stock-illustration-default-placeholder-profile-icon.jpg";
                  }
                }}
                alt={cast.actor_name}
                className=" relative z-0 rounded-lg h-[200px]"
              />
              <p
                className="font-semibold text-white  capitalize"
                title={cast.actor_name}
              >
                {cast.actor_name}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CastAvatar;
