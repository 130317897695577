import React from "react";
import { Link } from "react-router-dom";

const Platform = ({ fetchedMovieDetails }) => {
  const images = {
    Manorama: "../assets/images/Manorama-logo.webp",
    Hotstar: "../assets/images/disney_plus.webp",
    SonyLIV: "../assets/images/sony-logo.png",
    Zee5: "../assets/images/Zee5-logo.jpeg",
    "Amazon Prime": "../assets/images/amazon-logo.jpg",
    Netflix: "../assets/images/Netflix.png",
    AHA: "../assets/images/aha-logo.svg",
    "Google Play Movies": "../assets/images/google_play_movies.png",
    YouTube: "../assets/images/youtube.webp",
    "Apple TV+": "../assets/images/apple-logo.png",
    SunNXT: "../assets/images/SunNXT.webp",
    "VI movies and tv": "../assets/images/vi_movies_and_tv.png",
    "MX Player": "../assets/images/mx_player.png",
    Plex: "../assets/images/plex-logo.jpeg",
    "Tubi TV": "../assets/images/tubi_tv.png",
    JioCinema: "../assets/images/JioCinema.webp",
    yupptv: "../assets/images/yupptv-logo.png",
  };

  const getImageSrc = (host) => {
    return images[host] || "../assets/images/default-logo.png";
  };

  return (
    <div className=" flex flex-wrap">
      {fetchedMovieDetails.watch_links.length > 0 ? (
        fetchedMovieDetails.watch_links.map((link, index) => (
          <div key={index} className="flex flex-col p-2   ">
            {/* <h2
              className={`text-lg  mb-2 ${
                link.watch_link === ""
                  ? "text-gray-200 opacity-50"
                  : "text-gray-200"
              }`}
            >
              {link.watch_link === ""
                ? "Not available"
                : `Available on ${link.host}`}
            </h2> */}

            {link.watch_link && (
              <Link to={link.watch_link} target="_blank" className=" ">
                <div className=" flex flex-col  gap-2 items-center  ">
                  <img
                    src={getImageSrc(link.host)}
                    alt={link.host}
                    className="rounded-xl  w-20 h-20 object-contain"
                  />
                  <div
                    className={`px-2 py-1 text-xs font-bold rounded text-start inline-block ${
                      link.watch_type === "Rent"
                        ? "bg-yellow-200 text-yellow-800"
                        : "bg-indigo-200 text-indigo-800"
                    }`}
                  >
                    {link.watch_type} on {link.host}
                    {link.price ? ` - ${link.price}` : ""}
                  </div>
                </div>
              </Link>
            )}
          </div>
        ))
      ) : (
        <div className=" text-gray-500">No platform found</div>
      )}
    </div>
  );
};

export default Platform;
