import React from "react";

function ReccomandMovie({
  fetchedMovieDetails,
  handleImageClick,
  onRecommendationClick,
}) {
  return (
    <div className="grid gap-3 lg:grid-cols-5 sm:grid-cols-2 grid-cols-1">
      {fetchedMovieDetails.recommendations &&
        fetchedMovieDetails.recommendations.map((recommendation, index) => (
          <div key={recommendation.movie_id} className="p-2 text-left">
            <div className="relative mx-auto h-auto overflow-hidden rounded-lg hover:border border-gray-200 cursor-pointer">
              <img
                src={
                  recommendation.images ||
                  recommendation.images2 ||
                  "https://media.comicbook.com/files/img/default-movie.png"
                }
                onClick={() => {
                  onRecommendationClick(recommendation.movie_id);
                }}
                onError={(e) => {
                  if (
                    e.target.src.includes(recommendation.images) &&
                    recommendation.images2
                  ) {
                    e.target.src = recommendation.images2;
                  } else {
                    e.target.src = "../assets/images/alt-image.jpg";
                  }
                }}
                alt={`Recommendation ${index + 1}`}
                className="w-full h-auto relative z-0 rounded-lg transition-all duration-300 hover:scale-110"
              />
            </div>
            <p className="font-semibold text-white truncate capitalize">
              {recommendation.title}
            </p>
          </div>
        ))}
    </div>
  );
}

export default ReccomandMovie;
