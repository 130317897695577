import React from "react";
import { FaStar, FaRegStar } from "react-icons/fa";

const UserRating = ({ onRatingChange, userRating }) => {
  const stars = [1, 2, 3, 4, 5];

  const handleRatingChange = (selectedRating) => {
    onRatingChange(selectedRating);
  };

  return (
    <div className="">
      <div className="flex mb-1 gap-0.5">
        {stars.map((star) => (
          <span key={star} onClick={() => handleRatingChange(star)}>
            {star <= userRating ? (
              <FaStar
                style={{ transition: "color 0.3s ease" }}
                className="text-yellow-500 text-xl cursor-pointer"
              />
            ) : (
              <FaRegStar
                style={{ transition: "color 0.3s ease" }}
                className="text-gray-300 text-xl cursor-pointer"
              />
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

export default UserRating;
