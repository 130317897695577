import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { MdClose } from "react-icons/md";

const TicketNavbar = ({ setModalOpen, Cities, setSearchResult, isLoading, setIsLoading }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/cinemate/book_my_show/`
        );
        const data = await response.json();
        console.log("Initial data:", data);
        setSearchResult(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchSuggestions(searchQuery);
    } else {
      setSuggestions([]);
    }
  }, [searchQuery]);

  const fetchSuggestions = async (query) => {
    try {
      // setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/cinemate/book_my_show/?location=${query}`
      );
      const data = await response.json();
      setSuggestions(data.suggestions);
      // setIsLoading(false);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      // setIsLoading(false);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    setIsFocused(false)
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/cinemate/book_my_show/?location=${suggestion}`
      );
      const data = await response.json();
      console.log("Selected city data:", data);
      setSearchResult(data);
      setSearchQuery(suggestion);
      setIsFocused(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching selected city data:", error);
      setIsLoading(false);
    }
  };

  const handleCitySelect = async (city) => {
    setIsFocused(false)
    try {
      setIsLoading(true);
      setSearchQuery(city.name);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/cinemate/book_my_show/?location=${city.name}`
      );
      const data = await response.json();
      console.log("Selected city data:", data);
      setSearchResult(data);
      setIsFocused(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching selected city data:", error);
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
    setSuggestions([]);
    setIsFocused(true);
  };

  return (
    <div>
      <header className="fixed inset-x-0 top-0 z-40">
        <nav
          className="flex lg:justify-between justify-center items-center p-4 w-full bg-gray-800"
          aria-label="Global"
        >
          <div className="lg:flex hidden items-center">
            <Link to={"/"} className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-10 w-auto"
                src="../assets/images/logo.png"
                alt=""
              />
            </Link>
          </div>
          

          <div className="relative ">
            <div className="flex items-center border px-2 border-gray-50 rounded">
              <CiSearch className="text-xl text-blue-500" />
              <input
                type="text"
                className="px-4 py-2 bg-gray-800 focus:border-none focus:outline-none text-gray-50 lg:w-[600px]"
                placeholder="Search for your City..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <div className=" ml-2 text-xl">
                {searchQuery && (
                  <MdClose
                    className=" text-gray-50 cursor-pointer"
                    onClick={clearSearch}
                  />
                )}
              </div>
            </div>
            {isFocused && (
              <>
                <div
                  className="absolute top-full left-0 right-0 mt-2 bg-gray-900 z-50 rounded shadow-lg"
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <div className="flex flex-col py-4">
                    {!isLoading &&
                      searchQuery.length >= 2 &&
                      suggestions.length === 0 && (
                        <div className="text-center text-gray-50">
                          No suggestions found
                        </div>
                      )}
                    {suggestions.length > 0 && (
                      <div className="mb-4 px-4">
                        <h2 className="text-center text-lg mb-4 text-gray-50 font-semibold">
                          Search Results
                        </h2>
                        <div className="flex flex-wrap gap-10 items-center justify-center">
                          {suggestions.map((suggestion) => (
                            <div
                              key={suggestion}
                              className="flex flex-col items-center cursor-pointer"
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              <div className="font-semibold text-gray-50 truncate  px-2 py-1 rounded hover:bg-gray-800 transition-colors delay-100">
                                {suggestion}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {suggestions.length > 0 && <hr />}

                    <div className=" px-5">
                      <h2 className="text-center text-lg mb-4 mt-2 text-gray-50 font-semibold">
                        Popular Cities
                      </h2>
                      <div className="flex flex-wrap gap-10 items-center justify-center">
                        {Cities.map((city) => (
                          <div
                            key={city.name}
                            className="flex flex-col items-center cursor-pointer"
                            onClick={() => handleCitySelect(city)}
                          >
                            <img
                              src={city.image}
                              alt={city.name}
                              className={`lg:w-16 lg:h-16 w-10 h-10 rounded-full object-cover ${
                                selectedCity === city.name
                                  ? "border-4 border-blue-500"
                                  : ""
                              }`}
                            />
                            <div className="font-semibold text-gray-50 truncate">
                              {city.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex  space-x-6 items-center bg-red-500 border">
            {selectedCity && (
              <div
                className=" flex items-center gap-4 text-lg text-white cursor-pointer truncate border p-1"
                onClick={() => setIsFocused(true)}
              >
                {selectedCity}{" "}
                <span>
                  <FaChevronDown className=" text-xs" />
                </span>
              </div>
            )}

           
          </div>
        </nav>
        {/* <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to={"/"} className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-10 w-auto"
                  src="./assets/images/logo.png"
                  alt=""
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Link
                    to={"/signin"}
                    className="-mx-3 block rounded-lg px-3 font-Headings py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black"
                  >
                    signin
                  </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog> */}
      </header>
    </div>
  );
};

export default TicketNavbar;
