import React, { useEffect, useRef, useState } from "react";
import TicketNavbar from "./TicketNavbar";
import { CiSearch } from "react-icons/ci";
import TicketHero from "./TicketHero";
import Footer from "../footer/Footer";
import SkeletonLoader from "./SkeltonLoader";
import Metadata from "../metadata/MetaData";

const TicketBooking = () => {
  const [modalOpen, setModalOpen] = useState(true);
  const modal = useRef(null);
  const [searchResult, setSearchResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const Cities = [
    {
      name: "Kozhikode",
      image:
        "https://thumbs.dreamstime.com/z/calicut-kerala-india-black-white-city-logo-generative-ai-art-style-interpretation-270800672.jpg",
    },
    {
      name: "Kochi",
      image:
        "https://www.pngitem.com/pimgs/m/217-2172161_kochi-metro-hd-png-download.png",
    },
    {
      name: "Thrissur",
      image:
        "https://i.pinimg.com/originals/84/5f/91/845f91e270e7d008f22a0742870cb42a.png",
    },
    {
      name: "Kannur",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJ0AGrDHga_RNv1E6gv4PWhtqWdGOk5blZOA&s",
    },
    {
      name: "Alappuzha",
      image:
        "https://static.vecteezy.com/system/resources/previews/000/161/951/original/alappuzha-houseboats-kerala-silhouette-free-vector.jpg",
    },
    {
      name: "Kollam",
      image:
        "https://cdn.dribbble.com/users/1840570/screenshots/14269606/media/b917813978ff270e1c188973ca47d578.png",
    },
    {
      name: "Pathanamthitta",
      image:
        "https://static.vecteezy.com/system/resources/previews/023/795/123/non_2x/aranmula-boat-race-illustration-on-mandala-pattern-background-for-onam-festival-celebration-concept-vector.jpg",
    },
  ];

  return (
    <div className="bg-black w-full flex flex-col min-h-screen">
      <Metadata seoTitle={`Book tickets | Cinefinder4U `} />

      <TicketNavbar
        setModalOpen={setModalOpen}
        Cities={Cities}
        setSearchResult={setSearchResult}
        setIsLoading={setIsLoading}
      />
      <div className="container mx-auto py-20 flex flex-grow">
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          searchResult && <TicketHero searchResult={searchResult} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default TicketBooking;
