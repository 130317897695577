import React from "react";
import {
  MdAccessTime,
  MdOutlineDateRange,
  MdOutlineRemoveRedEye,
} from "react-icons/md";

const MostWatched = ({ MostWatched }) => {
  return (
    <div className="pb-6 flex flex-col border-b border-gray-600">
      <div className="flex justify-between mb-5">
        <h2 className="text-white font-medium text-xl tracking-wider">
          Most Watched
        </h2>
      </div>

      <section className="text-gray-600 body-font">
        <div className="flex flex-wrap -m-4">
          {MostWatched &&
            MostWatched.map((video) => (
              <div key={video.id} className="p-4 lg:w-1/3 w-full">
                <div className="h-full border border-gray-500 border-opacity-60 rounded-lg overflow-hidden bg-black">
                  <iframe
                    width="100%"
                    height="250"
                    src={video.links_}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; gyroscope; fullscreen"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                  <div className="flex flex-col p-4 gap-5 mb-2">
                    <div className="flex flex-wrap gap-2">
                      <div className="flex items-center gap-1 lg:border-r-2 pr-2 text-sm text-gray-400">
                        <MdAccessTime />
                        <span className="leading-tight tracking-tight">
                          {video.duration}
                        </span>
                      </div>
                      <div className="flex items-center gap-1 text-sm text-gray-400 border-r-2 pr-2">
                        <MdOutlineRemoveRedEye />
                        <span className="leading-tight tracking-tight">
                          {video.views}
                        </span>
                      </div>
                      <div className="flex items-center gap-1 text-sm text-gray-400  ">
                        <MdOutlineDateRange />
                        <span className="leading-tight tracking-tight">
                          {video.post_time}
                        </span>
                      </div>
                    </div>
                    <div className="leading-relaxed line-clamp-2 text-white">
                      {video.title}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default MostWatched;
