import React from "react";
import "../../App.css";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

const ImageCard = ({
  data,
  handlePagination,
  previousPage,
  nextPage,
  currentPage,
}) => {
  const handleImageClick = (link) => {
    if (link) {
      window.open(link, "_blank", "noopener,noreferrer");
    }
  };

  // Check if data is empty
  const isEmpty = Object.keys(data).length === 0;

  return (
    <div className="flex flex-wrap py-5">
      {isEmpty ? (
        <div className="flex flex-col items-center justify-center w-full h-full py-10">
          
          <p className="text-lg text-gray-50 text-center">No data available</p>
        </div>
      ) : (
        Object.keys(data).map((date) => (
          <div key={date} className="w-full mb-6">
            {date && (
              <h3 className="text-2xl font-bold text-gray-200 mb-4">
                {date?.split("-").reverse().join("-")}
              </h3>
            )}
            <div className="flex flex-wrap gap-4">
              {data[date].map((movie) => (
                <div
                  key={movie.id}
                  className={`overflow-hidden rounded-lg w-full md:w-1/4 lg:w-1/6 bg-black bg-opacity-50 flex justify-between flex-col ${
                    movie.link ? "cursor-pointer" : "cursor-default"
                  }`}
                  onClick={() => handleImageClick(movie.link)}
                >
                  <div className="relative">
                    <img
                      src={
                        movie.moviePosterUrl ||
                        "https://media.comicbook.com/files/img/default-movie.png"
                      }
                      onError={(e) => {
                        e.target.src =
                          "https://media.comicbook.com/files/img/default-movie.png";
                      }}
                      alt={movie.movie_name}
                      className="w-full h-full object-contain"
                    />
                    {movie.type && (
                      <div
                        className={`ribbon ${
                          movie.type.toLowerCase() === "series"
                            ? "ribbon-series"
                            : "ribbon-movie"
                        }`}
                      >
                        {movie.type}
                      </div>
                    )}
                  </div>
                  <div className="p-3">
                    <h3
                      className="text-lg font-semibold text-white line-clamp-1"
                      title={movie.movie_name}
                    >
                      {movie.movie_name}
                    </h3>
                    {movie.language && (
                      <p className="text-sm text-gray-400">{movie.language}</p>
                    )}
                    {movie.genre && (
                      <p className="text-sm text-gray-300 line-clamp-1 font-medium opacity-50">
                        {movie.genre.join(" . ")}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      )}
      {(previousPage || nextPage) && (
        <div className="flex bg-blue-600 px-2 py-2 gap-2 rounded items-center">
          <button
            disabled={!previousPage}
            onClick={() => handlePagination("previous")}
            title="Previous page"
            className="px-2 py-1 flex items-center text-white rounded hover:bg-blue-700 transition-colors disabled:opacity-40 disabled:cursor-not-allowed"
          >
            <span>
              <GrFormPrevious className="text-2xl" />
            </span>
          </button>
          <p
            title={`page ${currentPage}`}
            className="text-white border-x px-3 border-gray-400 font-semibold"
          >
            {currentPage}
          </p>
          <button
            disabled={!nextPage}
            onClick={() => handlePagination("next")}
            className="px-2 py-1 flex items-center text-white rounded hover:bg-blue-700 transition-colors disabled:opacity-40 disabled:cursor-not-allowed "
          >
            <span>
              <GrFormNext className="text-2xl" />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageCard;
