import React, { createContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [signInLoading, setSignInLoading] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setUser({ accessToken });
    }
  }, []);

  const register = async (username, email, password) => {
    setSignUpLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/cinemate/user/signup/`,
        { username, email, password }
      );

      if (response.status === 201) {
        setUser(response.data);
        console.log(response.data);
        alert("User created successfully");
        navigate("/signin");
      } else {
        throw new Error("Registration failed with status: " + response.status);
      }
    } catch (error) {
      console.error("Registration failed:", error);
      alert("Registration failed: " + error.response.data.data?.username);
    } finally {
      setSignUpLoading(false);
    }
  };

  const login = async (username, password) => {
    setSignInLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/cinemate/user/signin/`,
        { username, password }
      );

      if (response.status === 200) {
        const userData = response.data.data;
        if (userData && userData.access) {
          setUser(userData);
          localStorage.setItem("accessToken", userData.access);
          alert("SignIn successfully completed");

          // Retrieve the path from state or default to home
          const redirectPath = location.state?.from || "/";
          navigate(redirectPath);
        } else {
          alert("Login failed: Missing accessToken");
          throw new Error("Login failed: Missing accessToken");
        }
      } else {
        alert("Login failed: " + response.status);
        throw new Error("Login failed with status: " + response.status);
      }
    } catch (error) {
      console.error("Login failed:", error);
      alert("Login failed: " + error.response.data.message);
      setSignInLoading(false);
    } finally {
      setSignInLoading(false);
    }
  };

  const isLoggedIn = () => {
    return !!localStorage.getItem("accessToken");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        register,
        login,
        isLoggedIn,
        setUser,
        signInLoading,
        signUpLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
