import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import "../../App.css";

const SwiperSlider = ({ data, sliderRef, imageHeight }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(getItemsToShow());
  const [itemsToSwipe, setItemsToSwipe] = useState(getItemsToSwipe());

  function getItemsToShow() {
    if (window.innerWidth < 640) return 1; // Small screens
    if (window.innerWidth < 768) return 3; // Medium screens
    return 5; // Large screens and above
  }

  function getItemsToSwipe() {
    return window.innerWidth < 640 ? 1 : itemsToShow;
  }

  useEffect(() => {
    const handleResize = () => {
      setItemsToShow(getItemsToShow());
      setItemsToSwipe(getItemsToSwipe());
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clickNext = () => {
    setActiveIndex((prev) => {
      let newIndex = prev + itemsToSwipe;
      // Ensure newIndex does not exceed the length of the data
      if (newIndex + itemsToShow > data.length) {
        newIndex = data.length - itemsToShow;
      }
      return newIndex;
    });
  };

  const clickPrev = () => {
    setActiveIndex((prev) => {
      let newIndex = prev - itemsToSwipe;
      // Ensure newIndex does not go below zero
      if (newIndex < 0) {
        newIndex = 0;
      }
      return newIndex;
    });
  };

  const displayedReviews = data.slice(activeIndex, activeIndex + itemsToShow);

  const isNextDisabled = activeIndex + itemsToShow >= data.length;
  const isPrevDisabled = activeIndex <= 0;

  return (
    <div className="w-full flex flex-col">
      <div className="text-white space-x-3 flex justify-end">
        <button
          onClick={clickPrev}
          className={`bg-blue-500 text-gray-50 rounded-full p-1 text-xl hover:bg-blue-600 ${
            isPrevDisabled ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={isPrevDisabled}
        >
          <GrFormPrevious />
        </button>
        <button
          onClick={clickNext}
          className={`bg-blue-500 text-gray-50 rounded-full p-1 text-xl hover:bg-blue-600 ${
            isNextDisabled ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={isNextDisabled}
        >
          <GrFormNext />
        </button>
      </div>
      <div
        className={`grid gap-5 p-3 ${
          itemsToShow === 1
            ? "grid-cols-1"
            : itemsToShow === 3
            ? "grid-cols-3"
            : "grid-cols-5"
        }`}
        ref={sliderRef}
      >
        {displayedReviews.map((movie, index) => (
          <Link
            to={movie.link}
            key={index}
            target="_blank"
            className="flex-col p-2 text-left cursor-pointer inline-block"
          >
            <div className="w-full relative h-auto overflow-hidden rounded-lg border ">
              <img
                src={movie.image || "../../assets/images/alt-image.jpg"}
                alt={movie.title}
                loading="lazy"
                className="w-full  object-cover transition-all duration-300 hover:scale-105 rounded-lg"
                style={{ height: imageHeight }}
              />
              {movie.content_type_ && (
                <div className="ribbon bg-yellow-400 ">
                  {movie.content_type_}
                </div>
              )}
            </div>
            <p
              className="font-semibold text-lg text-white truncate capitalize mt-2"
              title={movie.title}
            >
              {movie.title}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SwiperSlider;
