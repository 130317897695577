import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FaYoutube } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="text-gray-600 body-font bg-black bottom-0 ">
      <div className=" px-5 py-8  mx-auto flex items-center sm:flex-row flex-col">
        <Link
          to={"/"}
          className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
        >
          <span className="ml-3 text-xl text-indigo-600">CINEFINDER <span className=" text-gray-300">4U</span></span>
        </Link>
        <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4 text-center">
          © {currentYear} Cinefinder4U —
          <Link
            to={"https://www.pfactorial.ai"}
            className="text-gray-600 ml-1"
            rel="noopener noreferrer"
            target="_blank"
          >
            @Pfactorial Technologies, Pvt Ltd
          </Link>
        </p>
        <span className="flex items-center flex-wrap sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
          <Link
            // to={"/terms-conditions"}
            target="_blank"
            className="ml-3 text-gray-500 text-sm hover:underline"
          >
            Terms & Conditions
          </Link>{" "}
          <Link
            // to={"/privacy"}
            target="_blank"
            className="ml-3 text-gray-500 text-sm hover:underline"
          >
            Privacy
          </Link>
          <Link
            to={"https://www.youtube.com/@PFACTORIALB14"}
            className="ml-3 text-gray-500 text-2xl"
            target="_blank"
          >
            <FaYoutube />
          </Link>
          <Link
            to={"https://www.instagram.com/pfactorial_technologies/"}
            className="ml-3 text-gray-500 text-2xl"
            target="_blank"
          >
            <AiFillInstagram />
          </Link>
          <Link
            to={
              "https://www.linkedin.com/company/pfactorial-technologies-pvt-ltd/"
            }
            className="ml-3 text-gray-500 text-2xl"
            target="_blank"
          >
            <FaLinkedin />
          </Link>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
