import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MostWatched from "./MostWatched";
import Footer from "../footer/Footer";
import axios from "axios";
import SkeltonLoader from "../ticketBooking/SkeltonLoader";
import Navbar from "../headers/Navbar";
import Metadata from "../metadata/MetaData";
const FreeMoviesHome = () => {
  const [channelDetails, setChannelDetails] = useState([]);
  const [mostWatched, setMostWatched] = useState([]);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMovieDetails = async () => {
      try {
        const url = `${process.env.REACT_APP_BASE_URL}/cinemate/channel_details/`;
        const response = await axios.get(url);
        setChannelDetails(response.data.channel_details);
        setMostWatched(response.data.most_watched);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching movie details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMovieDetails();
  }, []);
  const handleChannelClick = (channelId, channelDetails) => {
    console.log("clicked on channel");
    navigate(`/free-movies/channel/${channelId}`, {
      state: { channelDetails },
    });
  };
  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div className="bg-gray-800 w-full flex flex-col min-h-screen">
      <Metadata seoTitle={`Free Movies For You | Cinefinder4U `} />

      <Navbar bgColor={"bg-black"} />
      {loading ? (
        // Display a loader while data is loading
        <div className="flex flex-col w-full h-full flex-grow py-20 p-5">
          <div role="status">
            <SkeltonLoader />
            <span className="sr-only">Loading...</span>
          </div>{" "}
        </div>
      ) : (
        <div className="container mx-auto py-10 lg:px-20 px-5 flex-grow">
          <div className="relative border rounded-lg overflow-hidden h-64 border-gray-700 mb-8">
            <img
              src="https://www.taste.io/images/hero.jpg"
              alt=""
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 bg-black bg-opacity-60 p-10 flex flex-col justify-end">
              <h2 className=" text-2xl text-white font-semibold  tracking-wide capitalize">
                All free movies for you
              </h2>
            </div>
          </div>
          {channelDetails && (
            <div className=" pb-6 flex flex-col border-b border-gray-600 mb-8 ">
              <div className=" flex  justify-between mb-5 ">
                <h2 className=" text-white font-medium text-xl tracking-wider">
                  Discover Channels
                </h2>
                {/* <Link
              to={"/free-movies/all-channels"}
              className=" text-gray-300 font-semibold opacity-60 hover:underline transition-all delay-100"
            >
              Show more
            </Link> */}
              </div>

              <div className=" gap-4 flex flex-wrap justify-center items-center">
                {channelDetails &&
                  channelDetails.map((image) => (
                    <div
                      key={image.id}
                      onClick={() => handleChannelClick(image.id, image)}
                      className="flex flex-col items-center cursor-pointer  rounded-lg bg-gray-800 hover:bg-gray-700 transition-all duration-300 p-2 lg:w-1/6 w-full "
                    >
                      <img
                        src={
                          image.channel_image ||
                          "https://t3.ftcdn.net/jpg/04/60/01/36/360_F_460013622_6xF8uN6ubMvLx0tAJECBHfKPoNOR5cRa.jpg"
                        }
                        className="rounded-full lg:w-36 w-24 border-4 border-transparent mb-2 hover:border-blue-500 hover:shadow-xl transition-all duration-300"
                        alt=""
                        onError={(e) => {
                          e.target.src =
                            "https://t3.ftcdn.net/jpg/04/60/01/36/360_F_460013622_6xF8uN6ubMvLx0tAJECBHfKPoNOR5cRa.jpg";
                        }}
                      />
                      <div className=" text-white font-semibold text-center">
                        {image.channel_name}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          <MostWatched MostWatched={mostWatched} />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default FreeMoviesHome;
