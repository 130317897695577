import React from "react";
import { useState } from "react";
import { ImSpinner } from "react-icons/im";

export const formatTimeDifference = (timestamp) => {
  const currentTime = new Date();
  const createdTime = new Date(timestamp);
  const timeDifference = Math.floor((currentTime - createdTime) / 1000);

  if (timeDifference < 60) {
    return `${timeDifference} sec ago`;
  } else if (timeDifference < 3600) {
    const minutes = Math.floor(timeDifference / 60);
    return `${minutes} min${minutes !== 1 ? "s" : ""} ago`;
  } else if (timeDifference < 86400) {
    const hours = Math.floor(timeDifference / 3600);
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else {
    const days = Math.floor(timeDifference / 86400);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  }
};
export const generateStarIcons = (rating) => {
  const starIcons = ["★", "★★", "★★★", "★★★★", "★★★★★"];

  const ratingNumber = parseInt(rating.substr(1), 10);

  return starIcons[ratingNumber - 1];
};
function UserReview({
  onReviewChange,
  onPostReview,
  userReview,
  reviews,
  posting,
}) {
  return (
    <div>
      <div className="mb-3 flex flex-col justify-start gap-2 w-full">
        <textarea
          id="review"
          placeholder="Enter your review here..."
          value={userReview}
          onChange={onReviewChange}
          rows="4"
          className="w-full border border-yellow-300 text-gray-300 rounded px-2 py-1 resize-none  bg-[#82888b1f] focus:outline-none focus:ring-1 focus:ring-yellow-300"
        ></textarea>
        <div>
          <button
            className="bg-yellow-500 hover.bg-indigo-700 text-gray-900 font-semibold w-14 h-8 flex justify-center items-center rounded hover:bg-yellow-600"
            onClick={onPostReview}
            disabled={posting}
          >
            {posting ? <ImSpinner className=" animate-spin" /> : "Post"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserReview;
