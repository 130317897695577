import React, { useContext, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaUserCircle } from "react-icons/fa";
import { IoIosArrowDropdown } from "react-icons/io";
import { AuthContext } from "../authContext/AuthContext";

const Navbar = ({ bgColor }) => {
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    setUser(null);
    navigate("/signin");
  };

  return (
    <header className={` top-0 z-40 ${bgColor}`}>
      <div className="lg:px-5 mx-auto">
        <div className="relative flex items-center justify-between">
          <div className="max-w-full px-4">
            <Link to={"/"} className="block w-full">
              <img
                src="../../assets/images/logo.png"
                alt="logo"
                className="h-14 w-auto"
              />
            </Link>
          </div>
          <div className="flex w-full items-center justify-between px-4">
            <div>
              <button
                onClick={() => setOpen(!open)}
                id="navbarToggler"
                className={`${
                  open && "navbarTogglerActive"
                } absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden`}
              >
                <RxHamburgerMenu className="text-white text-3xl" />
              </button>
              <nav
                id="navbarCollapse"
                className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-gray-800 text-white lg:text-white px-6 py-5 shadow lg:static lg:block lg:w-full lg:max-w-full lg:shadow-none lg:bg-transparent ${
                  !open && "hidden"
                }`}
              >
                <ul className="block lg:flex">
                  <ListItem
                    NavLink="/genres"
                    isActive={location.pathname === "/genres"}
                  >
                    All Genres
                  </ListItem>
                  <ListItem
                    NavLink="/free-movies"
                    isActive={location.pathname === "/free-movies"}
                  >
                    Free Movies
                  </ListItem>
                  <ListItem
                    NavLink="/ticket/home"
                    isActive={location.pathname === "/ticket/home"}
                  >
                    Book Tickets
                  </ListItem>
                  <ListItem
                    NavLink="/upcoming-movies"
                    isActive={location.pathname === "/upcoming-movies"}
                  >
                    Upcoming
                  </ListItem>
                </ul>
              </nav>
            </div>
            <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
              {user ? (
                <div className="relative">
                  <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="flex items-center focus:outline-none gap-2"
                  >
                    <FaUserCircle className="text-white text-3xl" />
                    <span className="text-white text-xl">
                      <IoIosArrowDropdown />
                    </span>
                  </button>
                  {dropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2">
                      <button
                        onClick={handleLogout}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <Link
                    to={"/signin"}
                    className="px-7 py-3 text-base font-medium hover:text-white text-white"
                  >
                    Sign in
                  </Link>
                  <Link
                    to={"/signup"}
                    className="rounded-md bg-indigo-600 px-7 py-3 text-base font-medium text-white hover:bg-indigo-700"
                  >
                    Sign Up
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;

const ListItem = ({ children, NavLink, onclick, isActive }) => {
  const activeClassName = isActive ? "text-blue-500" : "text-white"; 

  return (
    <li>
      <Link
        to={NavLink}
        onClick={onclick}
        className={`flex py-2 text-base font-medium hover:text-blue-500 lg:ml-4 lg:inline-flex transition-colors ${activeClassName}`}
      >
        {children}
      </Link>
    </li>
  );
};
