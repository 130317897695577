import { useState, lazy, Suspense } from "react";
import "./Hero.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import Metadata from "../metadata/MetaData";
import { IoSearch, IoClose } from "react-icons/io5";
import Navbar from "./Navbar";
// import CustomSelect from "./CustomSelect";
import BgOverlay from "./BgOverlay";

export default function Hero() {
  const seoTitle = "Cinefinder 4U";
  const seoDescription = "Your streaming guide for Movies";
  const pageDescription =
    "Discover a world of endless entertainment as you navigate through an extensive collection of movies";
  const type = "website";

  const SuggestionList = lazy(() => import("./SuggestionList"));

  const [searchQuery, setSearchQuery] = useState("");
  const [movieDetails, setMovieDetails] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const navigate = useNavigate();
  const options = [
    { value: "movie", label: "Movies" },
    { value: "actors", label: "Actors" },
  ];

  const [selectedValue, setSelectedValue] = useState(options[0].value);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    setSearchQuery("");
    setTags([]);
  };

  const placeholderText = `Search for a ${selectedValue}`;

  const handleSearch = () => {
    const postPayload = {
      type: selectedValue,
      values: selectedValue === "movie" ? searchQuery : tags,
      page: 1,
    };

    // console.log(apiUrlWithQuery);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/cinemate/main/movie/`,
        postPayload
      )
      .then((response) => {
        // console.log(response);
        console.log("response data", response.data.values);
        if (
          Array.isArray(response.data.values) &&
          response.data.values.length === 0
        ) {
          // console.log("Movie not found");
          navigate("/movie-not-found");
        } else {
          setMovieDetails(response.data.values);
          navigate(`/moviespage`, {
            state: {
              movieDetail: response.data.values,
              inputQuery: postPayload,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error fetching Movie Details:", error);
        setMovieDetails(null);
      });
  };

  // Auto complete the movie
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);

    if (selectedValue === "actors") {
      setFilteredSuggestions(
        suggestions.filter((suggestion) =>
          suggestion.title.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }

    setTimeout(() => {
      if (inputValue && inputValue.length >= 3) {
        const apiUrlWithQuery = `${process.env.REACT_APP_BASE_URL}/cinemate/search_suggestions/?type=${selectedValue}&query=${inputValue}`;

        axios
          .get(apiUrlWithQuery)
          .then((response) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
              const data = response.data.map((item) => ({
                id: item.id,
                title: item.title,
                imagePath: item.image,
                imagePath2: item.image2,
              }));

              setSuggestions(data);
              console.log("suggestion list 3333333", data);
            } else {
              setSuggestions([]);
            }
          })
          .catch((error) => {
            console.error("Error fetching autocomplete suggestions:", error);
            setSuggestions([]);
          });
      } else {
        setSuggestions([]);
      }
    }, 500);
  };

  const [tags, setTags] = useState([]);

  const handleSuggestionClick = (suggestion) => {
    console.log("Clicked suggestion:", suggestion);

    if (selectedValue === "actors") {
      if (!tags.includes(suggestion.title)) {
        setTags((prevTags) => [...prevTags, suggestion.title]);
        setSearchQuery("");
        setSuggestions([]);
      }
    } else {
      const movieId = suggestion.id;
      console.log("suggestion movie id", movieId);
      if (movieId) {
        navigate(`/movie/${movieId}`);
      }
    }

    setSuggestions([]);
  };

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  return (
    <div className="bg-black w-full  flex flex-col min-h-screen">
      <Metadata
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        // pageTitle={pageTitle}
        pageDescription={pageDescription}
        type={type}
      />
      <Navbar />

      <div className="flex-grow relative isolate ">
        <BgOverlay />

        <div className="mx-auto container py-5 lg:py-3 lg:mt-5 flex flex-col ">
          <div className="text-center flex flex-col  ">
            <div className=" container mx-auto lg:max-w-4xl w-full ">
              <h1 className="text-4xl lg:text-6xl sm:text-6xl font-Headings tracking-tight text-white">
                Your streaming guide for{" "}
                <span className="text-indigo-600">Movies</span>
              </h1>
              <p className="mt-6 text-base sm:text-lg font-para lg:text-xl leading-6 text-gray-400">
                Discover a world of endless entertainment as you navigate
                through an extensive collection of movies, carefully curated to
                cater to every taste and mood.
              </p>
            </div>
            <div className="mt-5 flex flex-col relative lg:max-w-4xl container mx-auto ">
              <div className="flex  items-center bg-white rounded-sm p-2 ">
                <div className=" flex-grow lg:w-40 w-20 ">
                  <select
                    value={selectedValue}
                    onChange={handleSelectChange}
                    className="w-full bg-gray-50  rounded py-2 border  text-center text-gray-900 focus:ring-indigo-500 focus-within:outline-none"
                  >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="relative w-full  ">
                  <div className="flex flex-wrap items-center">
                    <input
                      type="text"
                      placeholder={placeholderText}
                      value={searchQuery}
                      onChange={handleInputChange}
                      className="rounded-md bg-white lg:px-3.5 py-2.5 text-md text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none h-12 flex-grow"
                    />
                    {tags &&
                      tags.map((tag) => (
                        <span
                          key={tag}
                          className="bg-gray-200 flex items-center text-gray-800 rounded-md px-2 py-1 m-1 text-xs"
                        >
                          {tag}
                          <button
                            onClick={() => handleTagRemove(tag)}
                            className="ml-1 text-gray-50 bg-gray-400 rounded-sm hover:bg-gray-500"
                          >
                            <IoClose className=" " />
                          </button>
                        </span>
                      ))}
                  </div>
                </div>
                <button
                  onClick={handleSearch}
                  disabled={!searchQuery && tags.length === 0}
                  className="rounded-md flex items-center lg:gap-2 bg-indigo-600 lg:px-5 lg:py-3.5 lg:p-0 p-1 text-sm font-Headings text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 lg:ml-2"
                >
                  <span aria-hidden="true" className="">
                    <IoSearch className="text-xl" />
                  </span>
                  <span className=" lg:flex hidden"> Search</span>
                </button>
              </div>
              {suggestions.length > 0 && (
                <Suspense
                  fallback={<div className="text-white">Loading...</div>}
                >
                  <SuggestionList
                    suggestions={suggestions}
                    setSearchQuery={setSearchQuery}
                    setSuggestions={setSuggestions}
                    handleSuggestionClick={handleSuggestionClick}
                  />
                </Suspense>
              )}
            </div>
          </div>
          <Link
            to={"/ticket/home"}
            className="mt-10  z-0 lg:px-2 px-4 lg:max-w-3xl w-full mx-auto "
          >
            <img
              src="../assets/images/bannerimage.png"
              alt=""
              className="w-full"
            />
          </Link>

          {/* <div className="  w-full ">
            <StreamingPlatforms />
          </div> */}
        </div>
      </div>
      <Footer />
      {/* <StickyIcon /> */}
    </div>
  );
}
