import React, { useEffect, useState } from "react";
import {
  MdAccessTime,
  MdOutlineRemoveRedEye,
  MdOutlineDateRange,
} from "react-icons/md";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import SkeletonLoader from "../ticketBooking/SkeltonLoader";
import Navbar from "../headers/Navbar";
import Footer from "../footer/Footer";

const Channel = () => {
  const location = useLocation();
  const { channelDetails } = location.state || {};

  const [movieDetails, setMovieDetails] = useState([]);
  const [allDetails, setAllDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchMovieDetails = async (page) => {
      try {
        const url = `${process.env.REACT_APP_BASE_URL}/cinemate/yt_movie_details/?channel_id=${channelDetails.id}&page=${page}`;
        const response = await axios.get(url);
        setMovieDetails(response.data.values);
        setAllDetails(response.data);
        console.log(response.data);
        setCurrentPage(page); 
      } catch (error) {
        console.error("Error fetching movie details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMovieDetails(currentPage);
  }, [currentPage, channelDetails.id]);

  const handleNextPage = () => {
    if (allDetails.next) {
      setCurrentPage(allDetails.next);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handlePreviousPage = () => {
    if (allDetails.previous) {
      setCurrentPage(allDetails.previous);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="bg-gray-900 w-full flex flex-col min-h-screen">
      <Navbar bgColor={"bg-black"} />
      {loading ? (
        <div className="flex flex-col w-full h-full flex-grow py-20 p-5">
          <div role="status">
            <SkeletonLoader />
            <span className="sr-only">Loading...</span>
          </div>{" "}
        </div>
      ) : (
        <div className="container mx-auto lg:py-10 lg:px-20 px-2">
          <div className="relative border rounded-lg overflow-hidden h-64 border-gray-700 mb-8">
            <img
              src="https://www.taste.io/images/hero.jpg"
              alt=""
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 bg-black bg-opacity-80 p-5 flex flex-col lg:justify-end">
              <div className="flex flex-col lg:flex-row items-center flex-wrap gap-4">
                <img
                  src={channelDetails.channel_image}
                  className="rounded-full lg:w-36 w-24"
                  alt=""
                />
                <div className="flex flex-col justify-center text-white gap-3  lg:text-start text-center">
                  <h2 className="lg:text-4xl text-2xl font-semibold">
                    {channelDetails.channel_name}
                  </h2>
                  <p className="text-xl text-gray-400">
                    {allDetails.video_count} videos
                  </p>
                </div>
              </div>
            </div>
          </div>

          <section className="text-gray-600 body-font">
            <div className="flex flex-col justify-between lg:mb-10 mb-5 px-3">
              <h2 className="text-white font-medium text-xl tracking-wider">
                Explore all
              </h2>
            </div>
            <div className="flex flex-wrap">
              {movieDetails &&
                movieDetails.map((video) => (
                  <div key={video.id} className="p-3 w-full lg:w-1/3 ">
                    <div className="h-full border border-gray-500 border-opacity-60 rounded-lg overflow-hidden bg-black">
                      <iframe
                        width="100%"
                        height="250"
                        src={video.links_}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; gyroscope; fullscreen"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                      <div className="flex flex-col p-4 gap-3 mb-2">
                        <div className="flex flex-row gap-2  flex-wrap">
                          <div className="flex items-center gap-1 lg:border-r-2 pr-2 text-sm text-gray-400 ">
                            <MdAccessTime />
                            <span className="leading-tight tracking-tight">
                              {video.duration}
                            </span>
                          </div>
                          <div className="flex items-center gap-1 text-sm text-gray-400 lg:border-r-2 pr-2">
                            <MdOutlineRemoveRedEye />
                            <span className="leading-tight tracking-tight">
                              {video.views}
                            </span>
                          </div>
                          <div className="flex items-center gap-1 text-sm text-gray-400 ">
                            <MdOutlineDateRange />
                            <span className="leading-tight tracking-tight">
                              {video.post_time}
                            </span>
                          </div>
                        </div>
                        <div className="leading-relaxed line-clamp-2 text-white">
                          {video.title}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="flex justify-center py-5">
              <div className="flex bg-blue-600 px-2 py-2 gap-2 rounded items-center">
                <button
                  onClick={handlePreviousPage}
                  disabled={!allDetails.previous}
                  title="Previous page"
                  className="px-2 py-1 flex items-center text-white rounded hover:bg-blue-700 transition-colors disabled:opacity-40 disabled:cursor-not-allowed"
                >
                  <span>
                    <GrFormPrevious className="text-2xl" />
                  </span>
                </button>
                <p
                  title={`page ${currentPage}`}
                  className="text-white border-x px-3 border-gray-400 font-semibold"
                >
                  {currentPage}
                </p>
                <button
                  onClick={handleNextPage}
                  title="Next page"
                  disabled={!allDetails.next}
                  className="px-2 py-1 flex items-center text-white rounded hover:bg-blue-700 transition-colors"
                >
                  <span>
                    <GrFormNext className="text-2xl" />
                  </span>
                </button>
              </div>
            </div>
          </section>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Channel;
