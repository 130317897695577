import React from "react";
import { formatTimeDifference, generateStarIcons } from "./UserReview";

const ReviewList = ({ reviews, reviewLoading }) => {
  return (
    <div>
      <div className="py-4 flex flex-col justify-start">
        {reviewLoading ? (
          <div class="border border-blue-500 shadow rounded-md p-4 max-w-xs ">
            <div class="animate-pulse flex flex-col gap-3 ">
              <div className=" grid grid-cols-3 gap-4 ">
                <div class="rounded-full bg-slate-700 h-10 w-10 col-span-2"></div>
                <div class="h-2 bg-slate-700 rounded  col-span-1"></div>
              </div>
              <div class="flex-1 space-y-6 py-1">
                {/* <div class="h-2 bg-slate-700 rounded"></div> */}
                <div class="space-y-3">
                  <div class="grid grid-cols-3 gap-4">
                    <div class="h-2 bg-slate-700 rounded col-span-2"></div>
                    <div class="h-2 bg-slate-700 rounded col-span-1"></div>
                  </div>
                  <div class="h-2 bg-slate-700 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        ) : reviews.length === 0 ? (
          <p>No reviews available.</p>
        ) : (
          reviews.map((review, index) => (
            <div
              key={index}
              className="flex flex-col flex-wrap rounded-lg bg-[#252e34] w-[300px] max-h-[210px] p-4 overflow-y-auto"
            >
              <div className="flex justify-between mb-3">
                <div className="flex">
                  <div>
                    <img
                      alt="User Avatar"
                      loading="lazy"
                      width="40"
                      height="40"
                      decoding="async"
                      className="rounded-full"
                      src="https://static.taste.io/images/user/avatar/14.jpg"
                    />
                  </div>
                  <div className="flex flex-col text-start leading-3 py-2 ml-3">
                    <div>User</div>
                    <div className="text-yellow-300 text-sm">
                      {generateStarIcons(review.star_rating)}
                    </div>
                  </div>
                </div>
                <div>
                  <span className="text-gray-400 text-sm text-end">
                    {formatTimeDifference(review.created_at)}
                  </span>
                </div>
              </div>

              <div className="text-[#e2e2e2] text-lg text-left">
                {review.comment}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ReviewList;
