import React from "react";

const SkeletonLoader = () => {
  return (
    <div class="w-full py-10 flex flex-wrap justify-center">
      <div className=" flex flex-col gap-2 p-4 w-1/4">
        <div class=" ">
          <div class="animate-pulse space-y-2">
            <div class="bg-gray-700 lg:h-48 h-20 "></div>
            <div class="flex-1 space-y-2">
              <div class="lg:h-6 h-3 bg-gray-700 full"></div>
              <div class="lg:h-6 h-3 bg-gray-700 w-2/3"></div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col gap-2 p-4 w-1/4">
        <div class=" ">
          <div class="animate-pulse space-y-2">
            <div class="bg-gray-700 lg:h-48 h-20 "></div>
            <div class="flex-1 space-y-2">
              <div class="lg:h-6 h-3 bg-gray-700 full"></div>
              <div class="lg:h-6 h-3 bg-gray-700 w-2/3"></div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col gap-2 p-4 w-1/4">
        <div class=" ">
          <div class="animate-pulse space-y-2">
            <div class="bg-gray-700 lg:h-48 h-20 "></div>
            <div class="flex-1 space-y-2">
              <div class="lg:h-6 h-3 bg-gray-700 full"></div>
              <div class="lg:h-6 h-3 bg-gray-700 w-2/3"></div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col gap-2 p-4 w-1/4">
        <div class=" ">
          <div class="animate-pulse space-y-2">
            <div class="bg-gray-700 lg:h-48 h-20 "></div>
            <div class="flex-1 space-y-2">
              <div class="lg:h-6 h-3 bg-gray-700 full"></div>
              <div class="lg:h-6 h-3 bg-gray-700 w-2/3"></div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col gap-2 p-4 w-1/4">
        <div class=" ">
          <div class="animate-pulse space-y-2">
            <div class="bg-gray-700 lg:h-48 h-20 "></div>
            <div class="flex-1 space-y-2">
              <div class="lg:h-6 h-3 bg-gray-700 full"></div>
              <div class="lg:h-6 h-3 bg-gray-700 w-2/3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
